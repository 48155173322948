import React, { useState, useEffect, useMemo, Suspense  } from 'react';
// import Webstock from '../src/webpages/routes/Webroutes';
import { ApplicationContext, UserRoleAccessContext, AuthContext } from './context/Appcontext';
// import Applicationstack from '../src/webpages/routes/Adminroutes';
import { APPSERVICE } from './webpages/services/Service';
import { AUTH } from "./webpages/services/Authservice";
import axios from 'axios';
import ScrollToTop from './Scrolltotop';
import Loading from "./Loading";

const Applicationstack = React.lazy(() => import("../src/webpages/routes/Adminroutes"));
const Webstock = React.lazy(() => import("../src/webpages/routes/Webroutes"));

function App() {

  const [auth, setAuth] = useState({ loginState: false, APIToken: "", loginuserid: "", loginUserDesignation: "", loginuserpassno: "" })
  const [isRegisterationData, setIsRegisterationData] = useState({ fullname: "", email: "" })
  const [isLoginUser, setIsLoginUser] = useState({});
  const [isLanguage, setIsLanguage] = useState(true);
  const [isUserRoleAccess, setIsUserRoleAccess] = useState({});
  const [allProjects, setAllprojects] = useState([]);
  const [alltaskLimit, setalltaskLimit] = useState([]);
  const [allTasks, setallTasks] = useState([]);
  const [allUsersLimit, setallUsersLimit] = useState([]);
  const [isUserRoleCompare, setIsUserRoleCompare] = useState([]);
  const [setngs, setSetngs] = useState();
  const [qrImage, setQrImage] = useState();
  const appContextData = useMemo(() => {
    return { auth, setAuth, qrImage, setQrImage, isLoginUser, setIsLoginUser, isLanguage, setIsLanguage, isRegisterationData, setIsRegisterationData, setngs, setSetngs }
  }, [auth, isLanguage, setngs, qrImage])

  const authContextData = useMemo(() => {
    return { auth, setAuth, qrImage, setQrImage, isLoginUser, setIsLoginUser, isLanguage, setIsLanguage, isRegisterationData, setIsRegisterationData, setngs, setSetngs }
  }, [auth, isLanguage, setngs, qrImage])


  const applicationContextData = useMemo(() => {
    return { isUserRoleAccess, setIsUserRoleAccess, isUserRoleCompare, setIsUserRoleCompare, allProjects, setAllprojects, allUsersLimit, setallUsersLimit, alltaskLimit, setalltaskLimit, allTasks, setallTasks };
  }, [auth, allProjects, isUserRoleCompare, alltaskLimit, allTasks, isUserRoleAccess, allUsersLimit]);


  const isCheckUserLogin = async () => {
    try {
      let getApiToken = localStorage.getItem('APIToken');
      let getLoginUserid = localStorage.getItem('LoginUserId');
      let getLoginUserdesignation = localStorage.getItem('LoginUserDesignation');
      let getLoginUserpassno = localStorage.getItem('LoginUserPassno');
      let getLanguage = localStorage.getItem('LanguageChange');
      let getLoginUserRole = localStorage.getItem("LoginUserrole");

      if (getLoginUserRole !== null) {
        const outputArray = getLoginUserRole.split(',');

        // if (getApiToken) {

        const [loginuserdata, userrole] = await Promise.all([
          axios.get(`${AUTH.GETUSER}/${getLoginUserid}`, {
            headers: {
              Authorization: `Bearer ${getApiToken}`,
            },
          }),
          axios.post(AUTH.GETAUTHROLE, {
            userrole: outputArray,
          }),
        ]);
        setIsUserRoleAccess(loginuserdata?.data?.suser);
        setIsUserRoleCompare(userrole?.data?.result);
      }


      let resuser = await axios.post(APPSERVICE.AUTHLOG_USER_ID, {
        loginuserpassno: String(getLoginUserpassno)
      });
      let idSetting = await axios.get(APPSERVICE.GETSINGLESETTINGS)
      setIsLanguage(getLanguage);




      if (resuser.data.result.length == 1) {
        setIsLoginUser(resuser.data.result[0]);
      } else if (resuser.data.excutive.length) {
        setIsLoginUser(resuser.data.excutive[0]);
      } else if (resuser.data.excutivehead.length == 1) {
        setIsLoginUser(resuser.data.excutivehead[0]);
      } else if (resuser.data.regional.length == 1) {
        setIsLoginUser(resuser.data.regional[0]);
      } else if (resuser.data.paos.length == 1) {
        setIsLoginUser(resuser.data.paos[0]);
      }

      setAuth((prevAuth) => {
        return { ...prevAuth, loginState: true, APIToken: getApiToken, loginuserid: getLoginUserid, loginUserDesignation: getLoginUserdesignation, loginuserpassno: getLoginUserpassno }
      });

      setSetngs(idSetting?.data?.result[0]);
      // } else {
      //   setAuth({ ...auth, loginState: false })
      // }
    }
    catch (err) {
      console.log(err);
      const messages = err?.response?.data?.message;
      if (messages) {
        console.log(messages);
      } else {
        console.log("Something went wrong!")
      }
    }
  }

  useEffect(() => {
    isCheckUserLogin();
  }, []);
  return (
    <>
      <div>
        
        <ApplicationContext.Provider value={appContextData}>
          <UserRoleAccessContext.Provider value={applicationContextData}>
            <AuthContext.Provider value={authContextData}>
              {/* <Webstock /> */}
              <Applicationstack />
            </AuthContext.Provider>
          </UserRoleAccessContext.Provider>
        </ApplicationContext.Provider>
      </div>
    </>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );
}